<template>
  <div style="width:100%;">
    <v-text-field v-model="searchTerm" solo dense hide-details class="pa-0" prepend-inner-icon="mdi-qrcode-scan"
      @click:prepend-inner="scanning" autocomplete="off" :placeholder="$t(scannerPayload.placeholder)"
      @click="selectContent" :id="scannerPayload.attrId" :ref="scannerPayload.attrRef" :disabled="disabled !== undefined ? disabled : false"
      :loading="loading !== undefined ? loading : false" :append-icon="searchTerm ? 'mdi-magnify' : ''"
      @click:append="scannerPayload.change"  @change="splitOnChange" @input="debouncedSearchBarcode"><!--  @change="scannerPayload.change" @keypress.enter="scannerPayload.change" @keydown.tab="scannerPayload.change" -->
    </v-text-field>
    <!-- SCAN BAR CODE -->
    <v-dialog v-model="scanDialog" persistent max-width="500px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t(scannerPayload.placeholder) }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          {{ scanError }}
            <div>
              <video id="video" style="border: 1px solid gray; width:100%; height:auto;" ref="video"></video>
            </div>
            <div id="sourceSelectPanel" v-if="videoInputDevices.length > 1">
              <v-select id="sourceSelect" v-model="selectedDeviceId" outlined :items="videoInputDevices" hide-details item-text="label" item-value="deviceId" :label="$t('message.common.changeVideoSource')"></v-select>
            </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" id="scanClose" @click="stopScanningSession">{{ $t('message.common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { BrowserMultiFormatReader } from '@zxing/library'
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  props: ['scannerPayload', 'search', 'loading', 'disabled', 'module'],
  model: {
    prop: 'search',
    event: 'custom'
  },
  data () {
    return {
      searchTerm: this.search,
      scanDialog: false,
      scanError: '',
      codeReader: {},
      videoInputDevices: [],
      selectedDeviceId: window.localStorage.getItem('WelditPwa_storeCamera') ? window.localStorage.getItem('WelditPwa_storeCamera') : 0,
      debouncedSearchBarcode: null
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi'])
  },
  mounted () {
    requestAnimationFrame(() => {
      if (this.$refs[this.scannerPayload.attrRef]) {
        this.$refs[this.scannerPayload.attrRef].focus()
      }
    })
    this.debouncedSearchBarcode = this.$formatter.debounce(this.scannerPayload.change, 2500)
  },
  methods: {
    splitOnChange (e) {
      const data = e[0] === '/' ? '/' : e[0] === '&' ? '&' : ''
      if (e.split('?')[0].includes('/')) {
        this.searchTerm = e.split('?')[0].replace('/', '')
      } else {
        this.searchTerm = e.split('?')[0]
      }
      if (data === '&') {
        if (/[^a-zA-Z0-9]/.test(e)) {
          this.searchTerm = e.replace(/&/g, '').replace(/_.*$/, '')
        }
      }
    },
    scanning (module) {
      const self = this
      this.scanDialog = true
      self.scanError = ''
      self.scanDialog = true
      // const selected = window.localStorage.getItem('WelditPwa_storeCamera')
      self.codeReader = new BrowserMultiFormatReader()
      self.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        self.videoInputDevices = videoInputDevices
        /* if (selected && selected !== 'undefined' && selected !== null) {
          self.selectedDeviceId = selected
        } else { */
        self.selectedDeviceId = this.$vuetify.breakpoint.mobile && videoInputDevices.length > 1 ? videoInputDevices[1].deviceId : videoInputDevices[0].deviceId
        // }
        this.scanFromVideoSource(self.selectedDeviceId, module)
      }).catch((err) => {
        console.log(err)
      })
    },
    scanFromVideoSource (videoDeviceId, module) {
      if (videoDeviceId) window.localStorage.setItem('WelditPwa_storeCamera', videoDeviceId)
      this.codeReader.decodeOnceFromVideoDevice(videoDeviceId, 'video')
        .then((result) => {
          if (result.text.includes('http') || result.text.includes('i=') || result.text.includes('m=') || result.text.includes('o=') || result.text.includes('t=')) {
            const newUrl = this.getPathFromUrl(result.text)
            const lastItem = newUrl.substring(newUrl.lastIndexOf('/') + 1)
            this.searchTerm = lastItem
          } else {
            this.searchTerm = result.text
          }
          this.stopScanningSession()
          setTimeout(() => {
            if (this.module === 'equipment' || this.module === 'chemicallist' || this.module === 'projectequipment') {
              const formDatas = {}
              if (result.text.match(/o=.*m=/) || result.text.match(/oid=.mid=/)) {
                result.text.split('?')[1].split('&').map(x => {
                  formDatas[x.split('=')[0]] = x.split('=')[1]
                })
              }
              const barcode = result.text.toString().split('?')[0].split('/')[1]
              const formData = new FormData()
              const data = Object.keys(formDatas)
              data.forEach(key => {
                formData.append(key, formDatas[key])
              })
              hostAppApi.post(`${this.getHostRefApi}qrcode/${barcode}`, formData)
                .then((response) => {
                  if (response.data.status === 'success') {
                    this.scannerPayload.change()
                  } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.noResults' })
                })
                .catch((e) => {
                  if (this.module === 'equipment') this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.mixed.noEquipmentFound' })
                  else if (this.module === 'chemicallist') this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.mixed.noChemicalFound' })
                  else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.noResults' })
                })
            } else {
              this.scannerPayload.change()
            }
          }, 50)
        }).catch((err) => {
          if (err && err.name && err.name === 'NotAllowedError') alert(this.$t('message.common.permissionDenied'))
        })
    },
    stopScanningSession () {
      this.scanDialog = false
      this.codeReader.reset()
      this.codeReader.stopContinuousDecode()
      this.codeReader = {}
    },
    selectContent () {
      if (this.$refs[this.scannerPayload.attrRef]) {
        setTimeout(() => {
          document.getElementById(this.scannerPayload.attrId).select()
        }, 50)
      }
    },
    getPathFromUrl (url) {
      return url.split('?')[0]
    },
    triggerEnterKey () {
      const event = new KeyboardEvent('keydown', {
        bubbles: true,
        cancelable: true,
        key: 'Enter',
        keyCode: 13,
        code: 'Enter',
        which: 13
      })
      const inputField = document.getElementById(this.scannerPayload.attrId)
      if (inputField) {
        inputField.dispatchEvent(event)
      }
    }
  },
  watch: {
    selectedDeviceId (val) {
      if (val) {
        window.localStorage.setItem('WelditPwa_storeCamera', val)
        this.scanFromVideoSource(val, this.module)
      }
    },
    search (val) {
      this.searchTerm = val.split('?')[0]
      if (!val) {
        this.triggerEnterKey()
      }
    },
    searchTerm () {
      this.$emit('custom', this.searchTerm.split('?')[0])
    }
  }
}
</script>
